import { FC } from 'react';
import { CopyIcon } from '@chakra-ui/icons';
import { useToast, Box, IconButton, useColorModeValue, chakra } from '@chakra-ui/react';

const CodeBlock = chakra(Box, {
  baseStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 1,
    paddingLeft: 3,
    borderRadius: 8,
    marginTop: 4,
    width: '100%',
  },
});

interface ICodeProps {
  code: string;
}

export const Code: FC<ICodeProps> = ({ code }) => {
  const { codeBlockBg, copyButtonBg, copyButtonHoverBg } = useColorModeValue(
    { codeBlockBg: 'gray.200', copyButtonBg: 'gray.300', copyButtonHoverBg: 'gray.400' },
    { codeBlockBg: 'gray.600', copyButtonBg: 'gray.700', copyButtonHoverBg: 'gray.800' },
  );
  const toast = useToast();

  const onCopyClick = () => {
    if (!navigator.clipboard) {
      toast({ title: 'Copying is not supported', status: 'warning', duration: 2000, position: 'top' });

      return;
    }
    navigator.clipboard.writeText(code);
    toast({
      title: 'Copied',
      status: 'success',
      duration: 2000,
      position: 'top',
    });
  };

  return (
    <CodeBlock bg={codeBlockBg}>
      <Box as="code" overflow="hidden" css={{ wordWrap: 'normal' }}>
        {code}
      </Box>
      {navigator.clipboard && (
        <IconButton
          onClick={onCopyClick}
          aria-label="Copy"
          icon={<CopyIcon />}
          bg={copyButtonBg}
          _hover={{ bg: copyButtonHoverBg }}
        />
      )}
    </CodeBlock>
  );
};
