import { Box, BoxProps, useColorModeValue } from '@chakra-ui/react';

export const Sticky = ({ children, ...props }: BoxProps) => {
  const boxColor = useColorModeValue('white', 'black');

  return (
    <Box position="sticky" top={-1} bg={boxColor} zIndex={2} {...props} outline={`1px solid ${boxColor}`}>
      {children}
    </Box>
  );
};
