import { Box, Heading } from '@chakra-ui/react';

import { usePreferences } from '@/contexts/PreferencesContext';

import { HeaderContainer } from './HeaderContainer';
import { Chart, ChartData, IChartState } from '../Chart';
import { Code } from '../Code';
import { useTranslation } from 'react-i18next';
import { useBrand } from '../../../hooks/useBrand';

export const Header = <TState extends IChartState>({
  isInternal,
  isOffer,
  urlParams,
  state,
  chart,
}: {
  isInternal?: boolean;
  isOffer?: boolean;
  urlParams?: string;
  state: TState;
  chart: ChartData<TState>;
}) => {
  const {
    preferences: { chart: chartEnabled },
  } = usePreferences();
  const { t } = useTranslation();
  const brand = useBrand();

  const offersHost = brand.internal ? brand.offerHost : window.location.host;
  const page = brand.internal ? '/' : '/offer';
  const offerUrl = `${window.location.protocol}//${offersHost}${page}#${urlParams}`;

  return (
    <HeaderContainer>
      {(isInternal || (chartEnabled && !isOffer)) && (
        <Box pb={50} pt={4}>
          {isInternal && <Code code={offerUrl} />}
          {!isInternal && !isOffer && chartEnabled && <Chart chart={chart} state={state} />}
        </Box>
      )}
      {isOffer && (
        <Heading as="h2" size="xl" textAlign="center" pb={8}>
          {t(`common.jobOfferTitle.${brand.brand}`)}
        </Heading>
      )}
    </HeaderContainer>
  );
};
