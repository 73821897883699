export enum Step {
  TotalCost = 'common.totalCost',
  TotalPay = 'common.totalPay',
  Gross = 'common.gross',
  Net = 'common.net',
  OnAccount = 'common.account',
  Government = 'common.government',
  Tax = 'common.tax',
  Perks = 'common.perks.text',
}
