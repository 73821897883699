import { Box, Select, useColorModeValue, Text } from '@chakra-ui/react';
import { ChangeEvent } from 'react';

import { useOffers } from '@/contexts/OfferContext';
import { EntryRow } from '@/components/shared/EntryRow';
import { useTranslation } from 'react-i18next';
import { COUNTIES } from '../../features/Croatia/constants/calculator';

const counties2023 = COUNTIES[2023];

export const SelectField = ({
  title,
  value,
  options,
  name,
  description,
  onChange,
  color,
}: {
  title: string;
  value: number;
  options: Array<{ value: number; name: string; info?: string }>;
  name: string;
  description?: string;
  onChange: (value: number) => void;
  color?: string;
  bg?: string;
}) => {
  const { t } = useTranslation();
  const { getFieldOfferState } = useOffers();
  const { isReadOnly, isReadOnlyValue } = getFieldOfferState(name);

  const bgColor = useColorModeValue('gray.200', 'gray.600');

  const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => onChange(parseInt(e.target.value, 10));

  const selected = options.find(({ value: optionValue }) => optionValue === value);

  return (
    <EntryRow title={title} name={name} description={description}>
      <Box alignItems="flex-end" display="flex" flexDirection="column">
        <Box maxW="220px" w="100%">
          <Select
            onChange={handleOnChange}
            defaultValue={value}
            border={0}
            bg={bgColor}
            color={color}
            fontSize="xl"
            isDisabled={isReadOnly || isReadOnlyValue}
            w="100%"
          >
            {options?.map(({ name, value }) => (
              <option key={value} value={value}>
                {t(name)}
              </option>
            ))}
          </Select>
          {selected?.info && (
            <Text variant="normal" pt={1.5} fontSize="sm" lineHeight="lg" textAlign="right">
              {selected.info}
            </Text>
          )}
          {name === 'stopa' && !options.find((item) => item.value === value) && (
            <Text variant="normal" pt={1.5} fontSize="sm" lineHeight="lg" textAlign="right" color="red">
              {counties2023.find((item) => item?.id === value)?.name} still didn&apos;t define their tax rate for 2024.
              <br />
              The deadline is 31st of December 2023
            </Text>
          )}
        </Box>
      </Box>
    </EntryRow>
  );
};
